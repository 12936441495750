<template>
  <div class="login">

    <form v-on:submit.prevent="logIn">
      <article class="message is-danger" v-if="hasError" :style="'opacity: '+ getErrorMsgOpacity">
        <div class="message-body">
          Forkert brugernavn eller kodeord
        </div>
      </article>
      <div class="field">
        <label class="label">Brugernavn</label>
        <p class="control">
          <input type="text" class="input" name="username" v-model="username" placeholder="Brugernavn" required>
        </p>
      </div>
      <div class="field">
        <label class="label">Kodeord</label>
        <p class="control">
          <input type="password" class="input" name="password" v-model="password" placeholder="Password" required>
        </p>
      </div>
      <div class="field">
        <p class="control is-flex is-justify-content-space-between is-align-items-center">
          <button type="submit" class="button is-success">
            Log ind
          </button>
          <span class="is-size-7 has-text-grey-lighter mb-0">Version: {{ version }} <span v-if="hasUpdated">(opdateret)</span></span>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import {Status} from '@/helpers';

export default {
  name: "LoginForm",
  props:['version', 'hasUpdated'],
  data(){
    return {
      username: '',
      password: '',
      hasError: false,
      errorMsgOpacity: 1
    }
  },
  computed:{
    getErrorMsgOpacity(){
      return this.errorMsgOpacity;
    }
  },
  methods:{
    logIn(){
      const objFetchData = {
        action: 'authenticateUser',
        username: this.username,
        password: this.password
      };

      if ( objFetchData.username && objFetchData.password ) {
        fetch( 'https://bankapp.lesion.dk/api/', {
          cache: 'no-store',
          method: 'POST',
          body: JSON.stringify( objFetchData )
        } ).then( response => response.json() ).then((json) =>{
          if(json.status === Status.SUCCESS){
            this.hasError = false;
            this.$store.commit('setUser', json);
            this.$router.push('/overview');
          } else{
            this.hasError = true;
          }
        });
      }
    },
    fadeOut(){
      let opacity = this.getErrorMsgOpacity;
      let vm = this;
      setTimeout(() =>{
        let timer = setInterval( () => {
          if (opacity <= 0.1){
            clearInterval(timer);
            vm.hasError = false;
          }
          vm.errorMsgOpacity = opacity;
          opacity -= opacity * 0.1;
        }, 50);
      }, 2000)
    }
  },
  watch:{
    hasError(newValue){
      if(newValue === true){
        this.fadeOut();
      } else{
        this.errorMsgOpacity = 1;
      }
    }
  }
};
</script>