<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand" style="width:100%;">
      <a class="navbar-item">
        <img :src="logo" width="100">
      </a>
      <div class="navbar-item ml-auto">
          <router-link class="navbar-item has-text-white-bis" v-if="isAuth && isAdmin" :to="{ name: 'User', params: { userId: '' } }">
            <font-awesome-icon icon="user-plus"  />
          </router-link>
          <router-link class="navbar-item has-text-white-bis" v-if="isAuth && isAdmin && !hasSecondaryUser" :to="{ name: 'User', params: { userId: admin.id, isAdmin: true } }">
            <font-awesome-icon icon="user-edit"  />
          </router-link>
          <router-link class="navbar-item has-text-white-bis" v-if="isAuth && isAdmin && hasSecondaryUser" :to="{ name: 'User', params: { userId: user.id, isAdmin: false } }">
            <font-awesome-icon icon="user-edit"  />
          </router-link>
          <a class="navbar-item has-text-white-bis" v-on:click="logout" v-if="isAuth">
            <font-awesome-icon icon="arrow-right-from-bracket" />
          </a>
        </div>
      </div>
  </nav>
</template>

<script>
import logo from '../assets/logo.svg';
export default {
  name: 'Navbar',
  data(){
    return{
      logo: logo,
      mobileMenuOpen: false
    }
  },
  computed:{
    user(){
      if(!this.$store.getters.isAdmin){
        return this.$store.getters.user;
      } else{
        return this.$store.getters.secondaryUser;
      }
    },
    admin(){
      if(this.$store.getters.isAdmin){
        return this.$store.getters.user;
      }
      return null;
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    isAuth(){
      return this.$store.getters.isAuth;
    },
    hasSecondaryUser(){
      return this.$store.getters.hasSecondaryUser;
    }
  },
  methods:{
    logout(){
      this.$store.commit('logout');
      this.$router.push('/');
    },
  }
};
</script>
