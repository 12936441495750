import Vue from "vue";
import VueRouter from "vue-router";
import Default from "../views/Default.vue";
import Overview from "../views/Overview.vue";
import User from "../views/User.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Default",
    component: Default,
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/user/:userId",
    name: "User",
    component: User,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
