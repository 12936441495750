import Vue from "vue";
import Vuex from "vuex";
import {UserTypes} from '@/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    secondaryUser:{},
    version: ''
  },
  getters:{
    session: () =>{
      return sessionStorage.getItem('bankapp.session');
    },
    version: () =>{
      return localStorage.getItem('bankapp.version');
    },
    user: (state) => {
      return state.user;
    },
    secondaryUser: (state) =>{
      return state.secondaryUser;
    },
    isAuth: (state) =>{
      return Object.keys(state.user).length > 0;
    },
    hasSecondaryUser: (state) =>{
      return Object.keys(state.secondaryUser).length > 0;
    },
    isAdmin: (state) =>{
      return state.user.type === UserTypes.ADMIN;
    },
    isSuperUser: (state) =>{
      return state.user.type === UserTypes.SUPERUSER;
    },
    isUser: (state) =>{
      return state.user.type === UserTypes.USER;
    }
  },
  mutations: {
    setVersion(state, payload){
      state.version = payload;
      localStorage.setItem('bankapp.version', state.version);
      setTimeout(() =>{
        location.href = `${location.href}?_v=${payload}`;
        location.reload();
      }, 100);
    },
    setUser(state, payload){
      state.user = payload;
      sessionStorage.setItem('bankapp.session', payload.id);
    },
    setSecondaryUser(state, payload){
      state.secondaryUser = payload;
    },
    logout(state){
      state.user = {};
      sessionStorage.removeItem('bankapp.session');
    }
  },
  actions: {},
});
