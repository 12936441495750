<template>
  <div id="app" class="container">
    <router-view v-if="appReady" />
  </div>
</template>

<script>

export default {
  name: "App",
  computed:{
    appReady(){
      return this.$root.appReady;
    }
  }
};
</script>