<template>
  <div class="overview">
    <Navbar />
    <div class="card" >
      <div class="card-content">
        <div class="content" v-if="isAdmin">
          <p class="is-size-4 mb-0 has-text-weight-light">
            Logget ind som Admin
          </p>
        </div>
        <div class="content" v-else>
          <p class="is-size-6 mb-0 is-flex is-justify-content-space-between" >
            <span>
              <span class="badge has-background-info-dark has-text-info-light">{{ userBadge }}</span> {{ user.name }}
            </span>
            <span class="is-flex is-align-items-center">{{ formatPrice(user.balance) }}</span>
          </p>
          <p>
          </p>
        </div>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item has-background-success-light" v-on:click="toggleTransactionModal" :disabled="!canMakeTransaction"><font-awesome-icon class="mr-1" icon="money-bill-transfer"  /> Transaktion</a>
        <a href="#" class="card-footer-item" v-if="isAdmin">
          <div class="select is-fullwidth" v-if="isAdmin">
            <select v-model="secondaryUserId" v-on:change="loadUser">
              <option selected value="">Ingen valgt</option>
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }}
              </option>
            </select>
          </div>
        </a>
      </footer>
    </div>
    <div class="card" >
      <div class="card-content">
      <div class="columns">
        <div class="column">
          <h3 class="is-size-4 mb-2">Posteringer <span v-if="isAdmin && secondaryUserId !== ''">for <span class="has-text-info is-italic">{{ user.name }}</span></span></h3>
          <table class="table is-striped is-fullwidth" v-if="user && user.entries && user.entries.length" style="vertical-align: middle;">
            <tbody>
            <tr :class="index === 0 ? 'has-background-success-light' :''" v-for="(entry, index) in user.entries" :key="entry.id">
              <td class="is-size-7-mobile" style="width: 20%;border-right: 1px solid #e1e1e1;">
                <div v-html="formatDate(entry.updated)" class="has-text-centered"></div>
              </td>
              <td class="has-text-weight-bold is-size-7-mobile">
                  <span v-if="entry.label">
                    {{ entry.label }}
                  </span>
                <span v-else>
                  <span v-if="entry.type === 0">
                    Hævet
                  </span>
                  <span v-else>
                    Indsat
                  </span>
                </span>
              </td>

              <td class="has-text-right is-size-7-mobile ">
                <span class="is-flex is-flex-direction-column">
                  <span class="has-text-weight-bold" v-html="getActivityByType(entry, index)"></span>
                <span class="is-size-7 has-text-grey">
                  {{ formatPrice(entry.balance) }}
                </span>
                </span>
                
                </td>
            </tr>
            </tbody>
          </table>
          <p class="is-italic has-text-grey-light" v-else>Ingen posteringer</p>
        </div>
      </div>
      </div>
    </div>
    <TransactionModal :isActive="transactionModalOpen" @toggleTransactionModal="toggleTransactionModal" />
  </div>
</template>


<script>
import TransactionModal from '@/components/TransactionModal';
import Navbar from '@/components/Navbar';

export default {
  name: "Overview",
  components:{
    TransactionModal,
    Navbar
  },
  data(){
    return{
      transactionModalOpen: false,
      secondaryUserId: '',
      users:[]
    }
  },
  computed:{
    user(){
      if(!this.$store.getters.isAdmin){
        return this.$store.getters.user;
      } else{
        return this.$store.getters.secondaryUser;
      }
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    canMakeTransaction(){
      if(this.isAdmin){
        return this.$store.getters.hasSecondaryUser;
      } else{
        return this.$store.getters.isAuth;
      }
    },
    userBadge(){
      let returnValue = '';
      let splitName = this.user.name.split(' ');
      let namesArr = [];
      if(splitName.length > 1){
        namesArr.push(splitName[0]);
        namesArr.push(splitName[splitName.length - 1]);
      }
      namesArr.forEach((namePart) =>{
        returnValue += namePart.substring(0,1);
      });
      return returnValue;
    }
  },
  methods: {
    formatPrice(price){
      const IntlNfDK = new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK'  });
      return IntlNfDK.format(price);
    },
    formatDate(entryDate){
      const date = new Date(entryDate);
      let dateStr = '';
      //const day = new Intl.DateTimeFormat('da-DK', {day: 'numeric'});
      const day = date.toLocaleString('da-DK', { day: 'numeric' });
      //const month = new Intl.DateTimeFormat('da-DK', {month: 'short'});
      const month = date.toLocaleString('da-DK', { month: 'short' });
      dateStr += `<span class="is-block day is-size-4 has-text-weight-bold">${day}</span>`;
      dateStr += `<span class="is-block is-uppercase month is-size-6">${month}</span>`;
      return dateStr;
    },
    getActivityByType(entry){
      if(entry.changed !== 0){
        if(entry.type === 0){
          return `<span class="has-text-danger is-normal">-${this.formatPrice(entry.changed)}</span>`;
        } else if(entry.type === 1){
          return `<span class="has-text-success is-normal">+${this.formatPrice(entry.changed)}</span>`;
        }
      } else{
        return `<span class="has-text-success is-normal">+${this.formatPrice(entry.balance)}</span>`;
      }
      
    },
    toggleTransactionModal(){
      this.transactionModalOpen = !this.transactionModalOpen;
    },
    loadUser(){
      if(isNaN(this.secondaryUserId) === false){
        fetch( 'https://bankapp.lesion.dk/api/', {
          cache: 'no-store',
          method: 'POST',
          body: JSON.stringify( {
            action: 'getUserById',
            userId: this.secondaryUserId
          } )
        } ).then( response => response.json() ).then((json) =>{
          this.$store.commit('setSecondaryUser', json);
        });
      } else{
        this.$store.commit('setSecondaryUser', {});
      }
    }
  },
  created(){
    if(this.$store.getters.isAdmin){
      fetch( 'https://bankapp.lesion.dk/api/', {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify( {
          action: 'getUsers'
        } )
      } ).then( response => response.json() ).then((json) =>{
          this.users = json;
          if(this.user){
            this.secondaryUserId = this.user.id;
          }
      });
    }
  }
};
</script>

<style scoped lang="scss">

a[disabled="disabled"]{
  pointer-events: none;
  color: hsl(0, 0%, 86%);
  opacity: .75;
}

.table.table{
  tr{
    td{
      vertical-align: middle;
    }
  }
}
.select{
  select{
    border: 0;
  }
}

.badge{
  border-radius: 100%;
  padding: .5em;
  height: 50px;
  width: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>