import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {Status} from '@/helpers';
import "../node_modules/bulma/bulma.sass";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoneyBillTransfer, faFolderPlus, faFolderMinus, faXmark, faSackDollar, faMessage, faKey, faArrowRightFromBracket, faUserPlus, faUserEdit, faUser, faUserTag  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false;

library.add(faMoneyBillTransfer, faFolderPlus, faFolderMinus, faXmark, faSackDollar, faMessage, faKey, faArrowRightFromBracket, faUserPlus, faUserEdit, faUser, faUserTag);

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  data(){
    return{
      appReady: false
    }
  },
  render: (h) => h(App),
  created() {
    window.addEventListener('pageshow',() =>{
      //alert('page show event')
    });
  },
  mounted() {
    if(this.$store.getters.session !== null){
      fetch( 'https://bankapp.lesion.dk/api/', {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify( {
          action: 'getUserById',
          userId: this.$store.getters.session
        } )
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$store.commit('setUser', json);
          this.appReady = true;
          if(this.$route.name !== 'Overview'){
            this.$router.push('/overview');
          }
          
        }
      });
    } else{
      this.appReady = true;
      if(this.$route.name !== 'Default') {
        this.$router.push( '/' );
      }
    }
  }
}).$mount("#app");
