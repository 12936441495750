<template>
  <div :class="isActive ? 'modal is-active' : 'modal'">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <font-awesome-icon class="mr-1" icon="money-bill-transfer" /> Transaktion
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="field is-horizontal">
              <div class="field-label is-flex is-justify-content-center is-align-items-center is-hidden-mobile">
                <label class="label">
                  <font-awesome-icon icon="money-bill-transfer" size="2x"  />
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="radio">
                      <input type="radio" value="1" name="transactionType" :checked="transactionType === 1" v-on:change="setTransactionType(1)">
                      Indsæt beløb
                    </label>
                    <label class="radio">
                      <input type="radio" value="0" name="transactionType" :checked="transactionType === 0" v-on:change="setTransactionType(0)">
                      Hæv beløb
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-flex is-justify-content-center is-align-items-center is-hidden-mobile">
                <label class="label"><font-awesome-icon icon="sack-dollar" size="2x" /></label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" type="number" v-model="amount" min="0" inputmode="decimal">
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-flex is-justify-content-center is-align-items-center is-hidden-mobile">
                <label class="label"><font-awesome-icon icon="message" size="2x" /></label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <textarea class="textarea" placeholder="f.eks. Købte robux" v-model="label"></textarea>
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="field is-horizontal" v-if="!isAdmin">
              <div class="field-label is-flex is-justify-content-center is-align-items-center is-hidden-mobile">
                <label class="label"><font-awesome-icon icon="key" size="2x" /></label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" type="password" placeholder="Kode" v-model="password">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" class="card-footer-item has-background-danger-light" v-on:click="closeModal"><font-awesome-icon class="mr-1" icon="xmark" />  Annuller</a>
          <a href="#" class="card-footer-item has-background-primary-light" v-on:click="makeTransaction"><font-awesome-icon class="mr-1" icon="money-bill-transfer" />  Gennemfør</a>
          
        </footer>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="closeModal"></button>
  </div>
</template>

<script>
import {Status} from '@/helpers';

export default {
  name: 'TransactionModal',
  props:['type', 'isActive'],
  data(){
    return {
      amount: 0,
      password: '',
      label: '',
      transactionType: 1
    }
  },
  computed:{
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
    adminId(){
      let returnValue = '';
      if(this.isAdmin){
        returnValue = this.$store.getters.user.id;
      }
      return returnValue;
      
    }
  },
  methods:{
    closeModal(){
      this.amount = 0;
      this.password = '';
      this.label = '';
      this.$emit('toggleTransactionModal');
    },
    setTransactionType(type){
      this.transactionType = type;
    },
    makeTransaction(){
      if(this.amount > 0 && (this.password !== '' || this.isAdmin)){
        fetch( 'https://bankapp.lesion.dk/api/', {
          cache: 'no-store',
          method: 'POST',
          body: JSON.stringify( {
            action: 'validateTransaction',
            adminId: this.adminId,
            isAdmin: this.isAdmin,
            controlPassword: this.password,
          } )
        } ).then( response => response.json() ).then((json) =>{
          if(json.status === 1){
            const user = this.$store.getters.isAdmin ? this.$store.getters.secondaryUser : this.$store.getters.user;
            const objPostData = {
              action: 'makeTransaction',
              amount: parseFloat(this.amount),
              transactionLabel: this.label,
              transactionType: this.transactionType,
              userId: user.id,
              currentBalance: user.balance,
              controlId: json.controlId
            };
            fetch( 'https://bankapp.lesion.dk/api/', {
              cache: 'no-store',
              method: 'POST',
              body: JSON.stringify( objPostData )
            } ).then( response => response.json() ).then((json) =>{
              if(json.status === Status.SUCCESS){
                delete json.status;
                if(this.$store.getters.isAdmin){
                  this.$store.commit('setSecondaryUser', json);
                } else{
                  this.$store.commit('setUser', json);
                }
                
                this.closeModal();
              }
            });
          } else{
            alert('ERROR');
          }
        });
      }
    }
  }
};
</script>