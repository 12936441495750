const Status = {
    ERROR: 0,
    SUCCESS: 1,
    FAIL: 2,
};

const UserTypes = {
    USER: 1,
    SUPERUSER: 2,
    ADMIN: 3,
};

export { Status, UserTypes }