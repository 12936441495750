<template>
  <form>
    <div class="field">
      <label class="label">Brugernavn</label>
      <div class="control has-icons-left">
        <input class="input" type="text" placeholder="Brugernavn" v-model="user.username">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="user" />
        </span>
      </div>
    </div>
    <div class="field">
      <label class="label">Kodeord</label>
      <div class="control has-icons-left">
        <input class="input" type="password" placeholder="Kodeord" v-model="userPassword" :disabled="!create && !canEditPassword">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="key" />
        </span>

        <label class="checkbox"  v-if="!create">
          <input type="checkbox" v-model="canEditPassword">
          Ændre kodeord
        </label>
      </div>
    </div>
    <div class="field">
      <label class="label">Navn</label>
      <div class="control has-icons-left">
        <input class="input" type="text" placeholder="Navn" v-model="user.name">
        <span class="icon is-small is-left">
          <font-awesome-icon icon="user-tag" />
        </span>
      </div>
    </div>
    
    <div class="field">
      <label class="label">Brugertype</label>
      <div class="control">
        <div class="select">
          <select v-model="user.type">
            <option value="1">Bruger</option>
            <option value="2">Superbruger</option>
            <option value="3">Admin</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field" v-if="!user.secure">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="user.secure" checked>
          Hash password
        </label>
      </div>
    </div>
    <hr />
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" v-if="create" v-on:click.prevent="createUser">Opret</button>
        <button class="button is-link" v-if="!create" v-on:click.prevent="updateUser">Opdater</button>
      </div>
      <div class="control">
        <button class="button is-danger" v-if="!create" v-on:click.prevent="deleteUser">Slet</button>
      </div>
      <div class="control">
        <router-link class="button is-link is-light" :to="{ name: 'Overview'}">
          Annuller
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import { Status } from '@/helpers';
export default {
  props:['user', 'create'],
  name: 'UserForm',
  data(){
    return{
      canEditPassword: false,
      userPassword:''
    }
  },
  methods:{
    updateUser(){
      const objFetchData = {
        action: 'updateUser',
        userId: this.user.id,
        name: this.user.name,
        username: this.user.username,
        type: this.user.type,
        insecure: !this.user.secure,
        setNewPassword: this.canEditPassword
      };
      
      if(this.canEditPassword){
        objFetchData.password = this.userPassword;
      }
      this.sendAction(objFetchData);
      
    },
    createUser(){
      const objFetchData = {
        action: 'createUser',
        name: this.user.name,
        username: this.user.username,
        password: this.user.password,
        type: this.user.type,
        insecure: this.user.secure
      };
      this.sendAction(objFetchData);
    },
    deleteUser(){
      if(confirm(`Delete user ${this.user.name}`)){
        const objFetchData = {
          action: 'deleteUser',
          userId: this.user.id,
        };
        this.sendAction(objFetchData);
      }
    },
    sendAction(objFetchData){
      fetch( 'https://bankapp.lesion.dk/api/', {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify( objFetchData )
      } ).then( response => response.json() ).then((json) =>{
        if(json.status === Status.SUCCESS){
          this.$emit('success');
        }
      });
    }
  }
};
</script>