<template>
  <div class="login">
    <Navbar />
    <section class="section">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="box">
            <LoginForm :version="version" :hasUpdated="hasUpdated" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import Navbar from '@/components/Navbar';
export default {
  name: "Default",
  components: {
    LoginForm,
    Navbar
  },
  data(){
    return {
      hasUpdated: false
    }
  },
  computed:{
    version(){
      return this.$store.getters.version;
    }
  },
  methods:{
    clearCache(){
      if ('serviceWorker' in navigator) {
        caches.keys().then(function(cacheNames) {
          cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName);
          });
        });
      }

    }
  },
  mounted() {
    fetch( 'https://bankapp.lesion.dk/api/', {
      cache: 'no-store',
      method: 'POST',
      body: JSON.stringify(
          {
            action: 'getVersion'
          }
      )
    } ).then( response => response.json() ).then((json) =>{
      let currentLocalVersion = this.version;
      if(currentLocalVersion !== json.version){
        this.clearCache();
        this.$store.commit('setVersion', json.version);
      } else{
        if(location.hash.indexOf('_v=') > -1){
          this.hasUpdated = true;
          location.replace(location.href.replace(location.hash, `#${this.$route.path}`));
        }
      }
    });
  }
};
</script>
