
<template>
  <div class="user">
    <Navbar />
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="box">
            <UserForm :user="user" :create="create" @success="onSuccess" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import Navbar from '@/components/Navbar';
import UserForm from '@/components/UserForm';
export default {
  name: 'User',
  components:{
    Navbar,
    UserForm
  },
  computed:{
    create(){
      return this.$route.params.userId === '';
    },
    user(){
      if(this.$route.params.userId !== ''){
        if(!this.$store.getters.isAdmin || this.$route.params.isAdmin){
          return this.$store.getters.user;
        } else{
          return this.$store.getters.secondaryUser;
        }
      } else{
        return {
          username:'',
          name:'',
          password:'',
          type:1,
          secure:false
        };
      }
    }
  },
  methods:{
    onSuccess(){
      this.$router.push('/overview');
    }
  }
};
</script>